<template>
  <div>
    <b-modal
      ref="modalRefuseProposal"
      :cancel-title="$t('Cancelar')"
      cancel-variant="outline-danger"
      size="lg"
      body-class=""
      :visible="false"
      :ok-title="$t('Enviar recusa')"
      ok-variant="primary"
      no-close-on-esc
      no-close-on-backdrop
      hide-header-close
      @ok="confirmRefuseProposal($event)"
      @cancel="hideModal"
    >
      <b-row class="">
        <b-col md="12">
          <h4>
            {{ $t('Proposta') }} {{ countProposal }} - {{ $t('O proprietário recusou') }}
          </h4>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="12 mt-1">
          <h5>
            {{ $t('Proposta') }}
          </h5>
        </b-col>
        <b-col
          class="col-12"
        >
          <p class="text-primary">
            {{ modalDataRefuse.sw130s15 }}
          </p>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="12 mt-1">
          <h5>
            {{ $t('Consultor comprador') }}
          </h5>
        </b-col>
        <b-col
          class="col-12"
        >
          <p
            v-if="typeof modalDataRefuse.aConsultor.nome !== 'undefined' && modalDataRefuse.aConsultor.nome !== null && modalDataRefuse.aConsultor.nome !== ''"
            class="text-primary"
          >
            {{ modalDataRefuse.aConsultor.nome }}
          </p>
          <p
            v-else
            class="text-primary"
          >
            {{ modalDataRefuse.aConsultor.nomeUser }}
          </p>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="12 mt-1">
          <h5>
            {{ $t('Tipo') }}
          </h5>
        </b-col>
        <b-col
          class="col-12"
        >
          <p class="text-primary">
            {{ modalDataRefuse.aLastProposal.sw007s02 }}
          </p>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="12 mt-1">
          <h5>
            {{ $t('Valor') }}
          </h5>
        </b-col>
        <b-col
          class="col-12"
        >
          <p
            v-if="modalDataRefuse.angariacao.currencyL !== ''"
            class="text-primary"
          >
            {{ modalDataRefuse.angariacao.currencyL }} {{ modalDataRefuse.aLastProposal.sw131s04 }}
          </p>
          <p
            v-if="modalDataRefuse.angariacao.currencyR !== ''"
            class="text-primary"
          >
            {{ formatPrice(modalDataRefuse.aLastProposal.sw131s04) }} {{ modalDataRefuse.angariacao.currencyR }}
          </p>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="12">
          <b-form-group
            :label="$t('Data')"
          >
            <date-picker
              v-model="vlData"
              value-type="format"
              input-class="form-control"
              type="date"
              :editable="false"
              :clearable="true"
              :disabled="true"
              :lang="langCalendar"
              :append-to-body="true"
              prefix-class="sw"
            >
              <template #icon-clear>
                <v-select-deselect />
              </template>
            </date-picker>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="12 mt-1">
          <h5>
            {{ $t('Motivo') }}
          </h5>
        </b-col>
        <b-col
          class="col-12"
        >
          <b-form-textarea
            v-model="vlMotivo"
            placeholder=""
            rows="3"
          />
        </b-col>
      </b-row>
      <b-row class="mt-1">
        <b-col md="12 mb-1">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-primary"
            block
            @click="$refs.refDocsProposal.$el.click()"
          >
            {{ $t('Carregar ficha de proposta digitalizada') }}
          </b-button>
          <b-form-file
            ref="refDocsProposal"
            accept="*"
            :hidden="true"
            plain
            multiple
            @input="uploadDocRender"
          />
        </b-col>
      </b-row>
      <div
        v-for="(row, index) in aFilesProposal"
        :key="`docs-${index}`"
        class="mt-1"
      >
        <b-row>
          <b-col md="12">
            <b-form-group
              :label="$t('Documento')"
            >
              <div class="text-truncate txt-label text-primary text-break-word">
                {{ row.name }}
              </div>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="12">
            <b-form-group
              :label="$t('Legenda')"
            >
              <b-form-input
                v-model="row.legend"
                type="text"
                autocomplete="off"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="12">
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-danger"
              class="btn-icon mb-1"
              @click="eliminarDoc(index)"
            >
              <span class="align-middle">{{ $t('Eliminar') }}</span>
            </b-button>
          </b-col>
        </b-row>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {
  VBTooltip, BModal, BRow, BCol, BFormTextarea, BFormGroup, BButton, BFormFile, BFormInput,
} from 'bootstrap-vue'
import '@/libs/sweet-alerts'
import { ref } from '@vue/composition-api'
import Ripple from 'vue-ripple-directive'
import { showModalDeleteMessage } from '@core/utils/utils'
import { sizeIconsAction } from '@core-custom/mixins/geral'
import { formatPrice } from '@core-custom/utils/ui'

const oDatepicker = () => Promise.all([
  import(/* webpackChunkName: "chunk-vue2-datepicker" */ 'vue2-datepicker'),
  import(/* webpackChunkName: "chunk-vue2-datepicker" */ '@/assets/scss/datepicker.scss'),
]).then(([DatePicker]) => DatePicker)

export default {
  components: {
    BModal,
    BRow,
    BCol,
    BFormTextarea,
    BFormGroup,
    BButton,
    BFormFile,
    BFormInput,
    DatePicker: oDatepicker,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  mixins: [sizeIconsAction],
  props: {
    modalDataRefuse: {
      type: Object,
      required: true,
    },
    countProposal: {
      type: Number,
      required: true,
      default: 0,
    },
    submitRefuseProposal: {
      type: Function,
      required: true,
    },
    showMsgErrorRequest: {
      type: Function,
      required: true,
    },
    showMsgSuccessRequest: {
      type: Function,
      required: true,
    },
  },
  data() {
    const now = new Date()
    let dataNow = `${now.getFullYear()}-${now.getMonth()}-${now.getDate()}`
    if (Number(now.getMonth()) < 10) {
      dataNow = `${now.getFullYear()}-0${now.getMonth()}-${now.getDate()}`
    }

    return {
      vlData: dataNow,
      vlMotivo: null,
      langCalendar: {},
      aFilesUpload: {},
      aFilesProposal: [],
      resumeErrorUpload: [],
      formatPrice,
    }
  },
  async mounted() {
    await this.importLocale(this.$i18n.locale).then(res => {
      this.langCalendar = res.default
      // Forçei estes valores para a semana começar sempre no domingo
      this.langCalendar.formatLocale.firstDayOfWeek = 0
      this.langCalendar.formatLocale.firstWeekContainsDate = 4
    })
  },
  methods: {
    showModal() {
      return new Promise(resolve => {
        this.$refs.modalRefuseProposal.show()
        resolve(true)
      })
    },
    hideModal() {
      this.$refs.modalRefuseProposal.hide()
    },
    async confirmRefuseProposal(evt) {
      evt.preventDefault()
      const txtMotivo = this.vlMotivo

      if (txtMotivo !== null && txtMotivo !== '') {
        if (this.aFilesUpload.length > 0) {
          this.$root.$emit('app::loading', true)
          await this.$store.dispatch('crm_proposals_events/uploadDocs', { files: this.aFilesUpload }).then(async result => {
            this.resetFieldUpload()

            Object.keys(result).forEach(index => {
              if (result[index].error === false) {
                this.resumeErrorUpload.push(result[index])
              } else {
                this.aFilesProposal[index].name = result[index].filename
              }
            })
          }).catch(error => {
            this.resetFieldUpload()
            this.showMsgErrorRequest(error)
          })
        }

        if (this.resumeErrorUpload.length > 0) {
          this.showMsgErrorRequest(new Error(this.$t('Ocorreu um erro a fazer upload dos documentos. Tente novamente.')))
        } else {
          await this.submitRefuseProposal()
        }
      } else {
        this.showMsgErrorRequest(new Error(this.$t('Tem de preencher o motivo da recusa')))
      }
    },
    resetFieldUpload() {
      this.resumeErrorUpload = []
      this.$refs.refDocsProposal.reset()
    },
    resetData() {
      this.vlMotivo = null
    },
    async importLocale(lang) {
      return import(`vue2-datepicker/locale/${lang}`)
    },
    async uploadDocRender(aFiles) {
      if (aFiles.length > 0) {
        this.aFilesUpload = aFiles
        for (let index = 0; index < aFiles.length; index += 1) {
          this.aFilesProposal.push({ name: aFiles[index].name, legend: '' })
        }

        if (aFiles.length === 1) {
          this.showMsgSuccessRequest({ message: this.$t('Ficheiro carregado com sucesso') })
        } else {
          this.showMsgSuccessRequest({ message: this.$t('Ficheiros carregados com sucesso') })
        }
      }
    },
    async eliminarDoc(index) {
      const actionModal = new Promise(resolve => {
        showModalDeleteMessage(resolve, `${this.$t('Pretende eliminar este documento?')}<br>${this.$t('Esta ação é irreversível!')}`)
      })

      await actionModal.then(result => {
        if (result === 'confirm') {
          this.aFilesProposal.splice(index, 1)
        }
      })
    },
  },
  setup() {
    const refDocsProposal = ref(null)

    return {
      refDocsProposal,
    }
  },
}
</script>

<style lang="scss" scoped>

.force-padding-left-05{
  padding-left: 0.5rem !important;
}

.force-padding-0{
  padding:0 !important;
}

</style>
